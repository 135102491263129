<template>
    <div>
        <Modal :title="`確認完成${checkedCount}筆通知嗎`" v-model="isShowModal">
            <template v-slot:modal-content>
                <div class="alert-word">
                    提醒您：當您點擊確認後，TIA
                    將不再針對以上通知進行推播，請務必確認已完成以上通知。
                </div>
            </template>
            <template v-slot:modal-bottom>
                <Button
                    buttonWord="取消"
                    buttonStyle="grey"
                    @click="goLastStep"
                />
                <Button
                    buttonWord="完成"
                    buttonStyle="blue"
                    @click="setComplete"
                />
            </template>
        </Modal>
        <SuccessModal v-model="isShowSuccessModal" message="儲存成功" />
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import SuccessModal from '@/containers/SuccessModal.vue'
import { completeTiaInsuranceNoticeAPI } from '@/assets/javascripts/api'

export default {
    name: 'CompleteNoticeModal',
    emits: [
        'update:modelValue',
        'update:selected',
        'updateinsservices',
        'golaststep'
    ],
    components: {
        Modal,
        Button,
        SuccessModal
    },
    props: {
        modelValue: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        selected: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        insServicesCount: {
            type: Number,
            default: 0
        },
        dataList: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    methods: {
        turnOffModal: function () {
            this.isShowModal = false
        },
        goLastStep: function () {
            this.$emit('golaststep')
            this.$setGaEvent('cancelDoneNotice', 'click-Button', {
                params: this.getPageParams()
            })
            this.turnOffModal()
        },
        setComplete: async function () {
            /*GA*/
            this.$setGaEvent('doneNotice', 'click-Button', {
                params: this.getPageParams()
            })
            this.$showLoading()
            this.$store.commit('set', { isShowModalForGa: false })
            const payload = { notifyIds: this.checkedNotice }
            try {
                if (!this.$hasPermission('completeNotice')) {
                    throw new Error('noPermission')
                }
                let res = await completeTiaInsuranceNoticeAPI(payload)
                if (res?.status === 200)
                    this.$emit('updateinsservices', this.checkedNotice)
                else throw res?.code
                //phase2 通知鈴
                // this.$getAllNotice()
                this.turnOffModal()
                this.$hideLoading()
                this.isShowSuccessModal = true
            } catch (error) {
                this.$hideLoading()
                this.goLastStep()
                if (error.message === 'noPermission') {
                    this.$showErrorMessage(
                        '此帳號無完成通知的權限',
                        '請確認您的帳號權限，如有疑問請洽飛鴿'
                    )
                } else {
                    this.$showErrorMessage(
                        '儲存時發生錯誤，請稍後再試',
                        '如持續發生此情形，請聯絡資訊客服'
                    )
                }
            }
        },
        getPageParams: function () {
            let params
            if (this.$route.params) {
                if (this.$route.params.mode && !this.$route.params.id)
                    params = this.$route.params.mode

                if (
                    this.$route.name === 'TiaInsurance' &&
                    this.$route.params.id
                )
                    params = 'detail'
            } else params = ''
            return params
        }
    },
    computed: {
        isShowModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        },
        checkedNotice: function () {
            return Object.keys(this.selected).filter(
                (value) => this.selected[value]
            )
        },
        dataListCount: function () {
            return Object.keys(this.dataList?.insServices).length
        },
        checkedCount: function () {
            const checked = this.checkedNotice?.length
            const insServices = this.insServicesCount

            if (insServices === 0) return checked ?? 0
            else if (checked > insServices)
                return checked - (this.dataListCount - insServices)
            else return checked
        }
    },
    watch: {
        isShowSuccessModal: function (newVal) {
            if (newVal) {
                this.$emit('update:selected', {})
                const checked = {}
                checked[this.$route.params.id] = this.checkedCount
                this.$store.commit('set', {
                    detailCheckedCount: {
                        ...this.$store.state.detailCheckedCount,
                        ...checked
                    }
                })
            }
        }
    },
    data() {
        return {
            isShowSuccessModal: false
        }
    }
}
</script>

<style lang="scss" scoped>
.alert-word {
    margin-bottom: 15px;
    font-size: 14px;
    color: $secondary-grey;
}
</style>
